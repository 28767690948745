import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Accordion } from "react-bootstrap";
import { hasCookie, setCookie } from "cookies-next";
import { getCookies,getCookie } from 'cookies-next';
const CookieBanner = () => {
  const [showConsent, setShowConsent] = useState(true);
  const [performance, setPerformance] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [functional, setFunctional] = useState(false);

  const [requiredCookie, setRequiredCookie] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShowConsent(hasCookie("localConsent"));
   
  }, []);

  const handleClose = () => setShow(false);

  const acceptAllCookies = () => {
    setShowConsent(true);
    setCookie("localConsent", "true", {expires:new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)});
    setShow(false);
  };
  const declineCookies = () => {
    setShowConsent(true);
    setShow(false);
  };

  const settingsHandler = () => {
    setShow(true);
  };
  if (showConsent) {
    return null;
  }

  return (
    <>
      {!show && (
        <div className="position-fixed bottom-0 w-100 cookie-banner">
          <Container>
            <Row className="d-md-flex align-items-center justify-content-between">
              <Col md={8}>
                <p>
                  Sitemizde deneyiminizi iyileştirmek için gerekli çerezleri
                  kullanmaktayız. Onayınızı almamız halinde performans
                  çerezlerinizi de işlemekteyiz.
                  <a href="/isnet-cerez-aydinlatma-metni">
                    Çerez Aydınlatma Metnimizi{" "}
                  </a>
                  okuyabilir ve çerez tercihlerinizi “Tercihler” seçeneğinden
                  değiştirebilirsiniz. “Kabul et”e tıklayarak, çerez
                  kullanımımıza onay vermiş olmaktasınız.
                </p>
              </Col>
              <Col md={2}>
                <Button className="w-100 mb-1" onClick={acceptAllCookies}>
                  Kabul Et
                </Button>
                <Button
                  className="btn w-100 mb-1"
                  //onClick={() => acceptCookies({ thirdParty: true })}
                  onClick={settingsHandler}
                >
                  Tercihler
                </Button>
                <Button className="w-100" onClick={declineCookies}>
                  Reddet
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Çerez Kullanımımız</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: 10 }}>
          <p>
            İnternet sitemizin çalışması için bir takım zorunlu çerezler
            kullanılmaktadır. İnternet sitemizde zorunlu çerezler dışında
            herhangi bir çerez kullanılmamaktadır. Kullandığımız zorunlu
            çerezlerle ilgili daha detaylı bilgi için lütfen çerez sayfamızı ve
            kişisel verilerin işlenmesine ilişkin aydınlatma sayfamızı ziyaret
            ediniz.
          </p>
          <hr />
          <Row className="d-md-flex align-items-center">
            <Col md={9}>
              <h5>Zorunlu Çerezler </h5>
              <p>
                Zorunlu çerezler internet sitemizin güvenlik, ağ yönetimi,
                erişilebilirlik gibi temel özelliklerini aktif eden çerezler
                olup, bu çerezler internet sitesinin sağlıklı çalışması için
                zorunludur. Bu çerezleri tarayıcı ayarlarınızı değiştirerek
                etkisiz hale getirebilirsiniz ancak bu halde sitemiz düzgün
                çalışmayabilir.
              </p>
            </Col>
            <Col md={3} className="text-end">
              <label className="switch">
                <input
                  type="checkbox"
                  name="required-cookie"
                  onChange={() => setRequiredCookie(!requiredCookie)}
                  checked={requiredCookie}
                />
                <span className="slider round"></span>
              </label>
            </Col>
          </Row>
          <Row className="d-md-flex align-items-center">
            <Col md={9}>
              <h5>Performans Çerezleri</h5>
              <p>
                İnternet sitemizde kullanıcıların davranışlarını analiz etmek
                amacıyla istatistiki ölçümüne imkân veren çerezlerdir. Bu
                çerezler, sitemizin iyileştirilmesi için kullanılmaktadır.
                Analitik çerezler, web sitesini nasıl kullandığınızı anlamamızı
                sağlar. Bu sayede sunduğumuz içerikleri geliştirebilir ya da
                internet sitesi tasarımını değiştirebiliriz. Bu çerezlere izin
                vermediğiniz takdirde sitemize ne zaman girdiğinizi ve nasıl
                kullandığını tespit edemeyiz.
              </p>
            </Col>
            <Col md={3} className="text-end">
              <label className="switch">
                <input
                  type="checkbox"
                  name="performance-cookie"
                  onChange={() => setPerformance(!performance)}
                  checked={performance}
                />
                <span className="slider round"></span>
              </label>
            </Col>
          </Row>
          <Accordion className="accordion-partners">
            <Accordion.Item eventKey="0">
              <Accordion.Header as="h5">
                <h5>Reklam/Pazarlama Çerezleri</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="d-md-flex align-items-center">
                  <Col md={9}>
                    <p>
                      Reklam ve pazarlama amaçlı çerezler ile internet ortamında
                      kullanıcıların çevrim içi hareketleri takip edilerek
                      kişisel ilgi alanlarının saptanıp bu ilgi alanlarına
                      yönelik internet ortamında kullanıcılara reklam
                      gösterilmesi hedeflenmektedirler. İnternet ortamında
                      birçok reklam türü bulunmasına karşın en çok tercih edilen
                      reklam türünün çevrim içi davranışsal reklamcılık
                      olmasının temel nedeni kişilerin eğilimlerine,
                      tercihlerine göre reklam yapılabilmesi ve reklam veren
                      açısından zaman ve maddi kaynakların daha verimli
                      kullanılabilmesidir. Çevrim içi davranışsal reklamcılık
                      uygulamaları; kişilerin internetteki faaliyetlerinin
                      izlenmesi, bu faaliyetlerin analiz edilerek profillenmesi,
                      profilleme yapılan kişinin uygun reklamlarla
                      eşleştirilerek söz konusu reklamların ilgili kişiye
                      gösterilmesi aşamalarından oluşmaktadır.
                    </p>
                  </Col>
                  <Col md={3} className="text-end">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="marketing-cookie"
                        onChange={() => setMarketing(!marketing)}
                        checked={marketing}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5>Fonksiyonel Çerezler</h5>
              </Accordion.Header>
              <Accordion.Body>
                <Row className="d-md-flex align-items-center">
                  <Col md={9}>
                    <p>
                      Web sitesi veya uygulamalarda (masaüstü, mobil veya IOT
                      cihazlardaki uygulamalar da dâhil olmak üzere) kullanılan
                      kişiselleştirme ve tercihlerin hatırlanması amaçları ile
                      kullanılan çerezlerdir. Bu çerezler zorunlu çerezler
                      dışında kalan işlevsellikleri sağlama amacıyla
                      kullanılmaktadır. İlgili kişinin bir bilgi toplum
                      hizmetini açıkça talep ettiğinin aşikâr olmadığı
                      durumlarda açık rızaya dayanılması gerekecektir.
                    </p>
                  </Col>
                  <Col md={3} className="text-end">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="functional-cookie"
                        onChange={() => setFunctional(!functional)}
                        checked={functional}
                      />
                      <span className="slider round"></span>
                    </label>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
          <Button variant="primary" onClick={acceptAllCookies}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CookieBanner;
